.commandbar-editor-drawer {
  position: fixed;
  z-index: 2147483644;
  transition: width 0s ease 0.3s, height 0s ease 0.3s, transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.commandbar-editor-drawer > * {
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    box-shadow 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.commandbar-editor-drawer.commandbar-editor-drawer-open {
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.commandbar-editor-drawer .commandbar-editor-drawer-mask {
  background: #000;
  opacity: 0;
  width: 100%;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), height 0s ease 0.3s;
}
.commandbar-editor-drawer-content-wrapper {
  position: absolute;
  background: #fff;
  max-width: unset;
}
#editor_nav {
  max-width: unset;
}
.commandbar-editor-drawer-content {
  overflow: auto;
  z-index: 1;
  position: relative;
}
.commandbar-editor-drawer-handle {
  position: absolute;
  top: 72px;
  width: 41px;
  height: 40px;
  cursor: pointer;
  z-index: 0;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.commandbar-editor-drawer-handle-icon {
  width: 14px;
  height: 2px;
  background: #333;
  position: relative;
  transition: background 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.commandbar-editor-drawer-handle-icon:before,
.commandbar-editor-drawer-handle-icon:after {
  content: '';
  display: block;
  position: absolute;
  background: #333;
  width: 100%;
  height: 2px;
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.commandbar-editor-drawer-handle-icon:before {
  top: -5px;
}
.commandbar-editor-drawer-handle-icon:after {
  top: 5px;
}
.commandbar-editor-drawer-left,
.commandbar-editor-drawer-right {
  width: 0%;
  height: 100%;
}
.commandbar-editor-drawer-left .commandbar-editor-drawer-content-wrapper,
.commandbar-editor-drawer-right .commandbar-editor-drawer-content-wrapper,
.commandbar-editor-drawer-left .commandbar-editor-drawer-content,
.commandbar-editor-drawer-right .commandbar-editor-drawer-content {
  height: 100%;
}
.commandbar-editor-drawer-left.commandbar-editor-drawer-open,
.commandbar-editor-drawer-right.commandbar-editor-drawer-open {
  width: 100%;
}
.commandbar-editor-drawer-left.commandbar-editor-drawer-open.no-mask,
.commandbar-editor-drawer-right.commandbar-editor-drawer-open.no-mask {
  width: 0%;
}
.commandbar-editor-drawer-left {
  top: 0;
  left: 0;
}
.commandbar-editor-drawer-left .commandbar-editor-drawer-handle {
  right: -40px;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
  border-radius: 0 4px 4px 0;
}
.commandbar-editor-drawer-left.commandbar-editor-drawer-open .commandbar-editor-drawer-content-wrapper {
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
}
.commandbar-editor-drawer-right {
  top: 0;
  right: 0;
}
.commandbar-editor-drawer-right .commandbar-editor-drawer-content-wrapper {
  right: 0;
}
.commandbar-editor-drawer-right .commandbar-editor-drawer-handle {
  left: -40px;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px 0 0 4px;
}
.commandbar-editor-drawer-right.commandbar-editor-drawer-open .commandbar-editor-drawer-content-wrapper {
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
}
.commandbar-editor-drawer-right.commandbar-editor-drawer-open.no-mask {
  right: 1px;
  transform: translateX(1px);
}
.commandbar-editor-drawer-top,
.commandbar-editor-drawer-bottom {
  width: 100%;
  height: 0%;
}
.commandbar-editor-drawer-top .commandbar-editor-drawer-content-wrapper,
.commandbar-editor-drawer-bottom .commandbar-editor-drawer-content-wrapper,
.commandbar-editor-drawer-top .commandbar-editor-drawer-content,
.commandbar-editor-drawer-bottom .commandbar-editor-drawer-content {
  width: 100%;
}
.commandbar-editor-drawer-top .commandbar-editor-drawer-content,
.commandbar-editor-drawer-bottom .commandbar-editor-drawer-content {
  height: 100%;
}
.commandbar-editor-drawer-top.commandbar-editor-drawer-open,
.commandbar-editor-drawer-bottom.commandbar-editor-drawer-open {
  height: 100%;
}
.commandbar-editor-drawer-top.commandbar-editor-drawer-open.no-mask,
.commandbar-editor-drawer-bottom.commandbar-editor-drawer-open.no-mask {
  height: 0%;
}
.commandbar-editor-drawer-top .commandbar-editor-drawer-handle,
.commandbar-editor-drawer-bottom .commandbar-editor-drawer-handle {
  left: 50%;
  margin-left: -20px;
}
.commandbar-editor-drawer-top {
  top: 0;
  left: 0;
}
.commandbar-editor-drawer-top .commandbar-editor-drawer-handle {
  top: auto;
  bottom: -40px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 0 0 4px 4px;
}
.commandbar-editor-drawer-top.commandbar-editor-drawer-open .commandbar-editor-drawer-content-wrapper {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.commandbar-editor-drawer-bottom {
  bottom: 0;
  left: 0;
}
.commandbar-editor-drawer-bottom .commandbar-editor-drawer-content-wrapper {
  bottom: 0;
}
.commandbar-editor-drawer-bottom .commandbar-editor-drawer-handle {
  top: -40px;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px 4px 0 0;
}
.commandbar-editor-drawer-bottom.commandbar-editor-drawer-open .commandbar-editor-drawer-content-wrapper {
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.15);
}
.commandbar-editor-drawer-bottom.commandbar-editor-drawer-open.no-mask {
  bottom: 1px;
  transform: translateY(1px);
}
.commandbar-editor-drawer.commandbar-editor-drawer-open .commandbar-editor-drawer-mask {
  /* opacity: 0.3; */
  height: 100%;
  transition: opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.commandbar-editor-drawer.commandbar-editor-drawer-open .commandbar-editor-drawer-handle-icon {
  background: transparent;
}
.commandbar-editor-drawer.commandbar-editor-drawer-open .commandbar-editor-drawer-handle-icon:before {
  transform: translateY(5px) rotate(45deg);
}
.commandbar-editor-drawer.commandbar-editor-drawer-open .commandbar-editor-drawer-handle-icon:after {
  transform: translateY(-5px) rotate(-45deg);
}

/* RC Drawer */

.commandbar-editor-drawer-content-wrapper {
  margin: 10px 0px;
  box-shadow: none !important;
  height: calc(100% - 20px) !important;
  background-color: transparent !important;
}

.commandbar-editor-drawer-content {
  overflow: hidden !important;
}
